import axios from 'axios';

const getAllContracts = async () => {
     const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getContracts")
     return result.data;
}


const getAllLegacyContracts = async () => {
     const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getLegacyContracts")
     return result.data;
}



const getContract = async (id) => {
     const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getContract/"+id)
     return result.data;
}

const getLegacyContract = async (id) => {
     const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getLegacyContract/"+id)
     return result.data;
}


const createContract  = async (data) => {
     const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/createContract",{
          data :data
     })
     return result.data;
}

const getContractsByContractor = async (swv) => {
     const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getContractsByContractor/" + swv)
     return result.data;
}

const uploadContract = async (data) => {
     const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/uploadContract",{
          data :data
     })
     return result.data;
}

export {getAllContracts,getContractsByContractor,createContract,getContract,uploadContract, getLegacyContract,getAllLegacyContracts}