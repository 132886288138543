import axios from 'axios';



let options = {
	"content-type": "text/json",
	"Access-Control-Allow-Origin": "*",
};

const saveProgram = async (data) => {
     const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/saveProgram",data,options)
     return result.data;
}


const getAllAccounts = async () => {
     const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getAllAccounts",options)
     return result.data;
}

const getAllCountyCodes = async () => {
     const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getAllCountyCode",options)
     return result.data;
}

const getAllPrograms = async () => {
     const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getPrograms",options)
     return result.data;
}

const getAllMasterIndex = async () => {
     const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getAllMasterIndex",options)
     return result.data;
}

const getAllMasterIndexByBiennium = async (bi) => {
     const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getAllMasterIndexByBiennium/"+ bi,options)
     return result.data;
}



export {getAllPrograms,saveProgram,getAllAccounts,getAllCountyCodes,getAllMasterIndex,getAllMasterIndexByBiennium}