<template>
    <v-card>
      <v-card-title>
      Activity Logs
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      </v-card-title>
  <v-data-table
    :headers="headers"
    :items="logs"
    :items-per-page="15"
    class="elevation-1"
    :search="search"
  >
  <template v-slot:item.timesince="{item }">
       {{ daysElapsed(item.timestamp) }} Days
      </template>
  
  <template v-slot:item.timestamp="{item }">
       {{ formatDate(item.timestamp) }}
      </template>

  
</v-data-table>
  </v-card>
</template>

<script>
import { getLogs } from '../dataservices/adminDataService';

export default {
methods: {
  daysElapsed(isoTimestamp){
    let pastDate = new Date(isoTimestamp);
    let currentDate = new Date();
    let differenceInMillis = currentDate - pastDate;

// Convert milliseconds to days
let millisecondsPerDay = 24 * 60 * 60 * 1000;
return Math.floor(differenceInMillis / millisecondsPerDay);
  },
  formatDate(isoTimestamp){
    let date = new Date(isoTimestamp);
    let month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
let day = String(date.getUTCDate()).padStart(2, '0');
let year = date.getUTCFullYear();
let hours = String(date.getUTCHours()).padStart(2, '0');
let minutes = String(date.getUTCMinutes()).padStart(2, '0');
let seconds = String(date.getUTCSeconds()).padStart(2, '0');

// Format the date and time
return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
  },
},
  async mounted(){
    this.logs = await getLogs();
  },
    data(){
        return{
          logs : [],
            search : "",
            headers : [ 
                 { text: 'Time', value: 'timestamp' },
                 { text: 'Request URL', value: 'url' },
                 { text: 'User Agent', value: 'userAgent' },
                 { text: 'Requestor', value: 'user' },
                 { text: 'Uid of Requestor', value: 'uid' },
                 { text: 'Payload', value : 'requestBody'}
            ],
     
        }
    },
}
</script>

<style>

</style>