<template>
  <div class='ma-3'>

    <v-breadcrumbs
      :items="breadcrumbsitems"
      large
    ></v-breadcrumbs>

    <v-data-table
      :items-per-page='30'
      item-key="row_id"
      v-model="selectedContract"
      :loading='loading'
      loading-text="Loading... Please wait"
      multi-sort
      :sort-by="sortby"
      :sort-desc="sortDesc"
      :headers="headers"
      :items="contracts"
      :search="search"
    >

      <template v-slot:item.SumOfFYTotalAmt="{ item }">

        ${{ item.SumOfFYTotalAmt }}

      </template>
      <template v-slot:item.SumGLAmount="{ item }">

        ${{ item.SumGLAmount }}

      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getContractsByContractor } from "../dataservices/contractsDataservices";
export default {
  async mounted() {
    this.contracts = await getContractsByContractor(this.$route.params.id);
    this.loading = false;
  },
  data() {
    return {
      breadcrumbsitems: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Contractors",
          disabled: false,
          href: "/contractors",
        },
        {
          text: "All Contract for " + this.$route.params.id,
          disabled: true,
          href: "",
        },
      ],
      selectedContract: [],
      search: null,
      contracts: [],
      loading: true,
      sortby: [],
      sortDesc: [],
      headers: [
        {
          text: "Fiscal Year",
          align: "start",
          filterable: true,
          value: "FiscalYear",
        },
        { text: "Contract Status", value: "ContractStatus" },
        { text: "County", value: "County" },
        { text: "County Names", value: "CountyNames" },
        { text: "Months", value: "Months" },
        { text: "Sum Of FY Total Amt", value: "SumOfFYTotalAmt" },
        //{ text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style>
</style>