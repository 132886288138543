<template>
    <v-card>
      <v-card-title>
      Activity Log
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      </v-card-title>
  <v-data-table
    :headers="headers"
    :items="data"
    :items-per-page="5"
    class="elevation-1"
    :search="search"
  ></v-data-table>
  </v-card>
</template>

<script>
export default {
    data(){
        return{
            search : "",
            headers : [
                 { text: 'Date', value: 'date' },
                 { text: 'Type', value: 'type' },
                 { text: 'Detail', value: 'detail' },
            ],
            data : [{
                date : "04-01-2023 09:15",
                type : "System",
                detail : "Contract status changed from executed to closed by FISCHXS."
            }]
        }
    },
}
</script>

<style>

</style>