<template>
    <div class='ma-2'>
      <v-card-title>Users</v-card-title>
  
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            dense
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
  
        <v-data-table
    :expanded.sync="expanded"
    item-key="row_id"
    :items-per-page="20"
    v-model="selected"
    :loading="loading"
    loading-text="Loading... Please wait"
    multi-sort
    :sort-by="sortby"
    :sort-desc="sortDesc"
    
    :headers="headers"
    :items="users"
    show-expand
    :search="search"
    class="elevation-1"
  >
    <template v-slot:item.appLevel="{ item }">
      <v-chip-group active-class="primary--text" column>
        <v-chip
          v-for="tag in formatName(item.customClaims.appLevel)"
          :key="tag"
          small
          class="ma-1"
        >
          {{ tag }}
        </v-chip>
      </v-chip-group>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn title='resend reset password email' icon small @click="resendEmailDialog(item)">
        <v-icon small>mdi-mail</v-icon>
      </v-btn>
      <v-btn icon small @click="editItem(item)">
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="pa-5">
        <v-card flat>
          <v-card-title class="text-h6 font-weight-bold">
            Additional Details
          </v-card-title>
          <v-data-table
            :headers="expandedHeaders"
            :items="item.customClaims.roles"
            hide-default-footer
            class="elevation-0"
          >
            <template v-slot:item.appId="{ item: role }">
              {{ appName.find(a => a.id === role.appId).name }} ({{role.appId}})
            </template>
          </v-data-table>
        </v-card>
      </td>
    </template>
  </v-data-table>
      </v-card>

      <v-dialog v-model="emaildialog" max-width="400px">
    <v-card>
      <v-card-title class="headline">
        Confirm Resend Password Reset Email
      </v-card-title>
      <v-card-text>
        Are you sure you want to resend the password reset email?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="emaildialog = false">
          Cancel
        </v-btn>
        <v-btn :loading="resendLoading" color="blue darken-1" text @click="resendEmail">
          Resend
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  
      <!-- Edit Modal -->
      <v-dialog v-model="dialog" max-width="900px">
        <NewUser :editMode='true' :userObj="editedItem" :metaData="usersRaw?.metadata?.find(u => u.User_uuid === editedItem.uid) ?? null" />
 
  </v-dialog>
    </div>
  </template>

<script>

import { getAllUsers } from "../dataservices/adminDataService";
import { resetPassword } from "../dataservices/adminDataService";
import NewUser from "../views/NewUser.vue"

export default {
  components : {
    NewUser : NewUser
  },
  data() {
    return {
      resendLoading : false,
      rolesList : [
      {
                name : "Admin App",
                role: 'User',
                title: null,
                org: null,
                appId: 0,
              },
              {
                name : "OPDCS",
                role: 'User',
                title: null,
                org: null,
                appId: 1,
              },
              {
                name : "CATTS",
                role: 'User',
                title: null,
                org: null,
                appId: 2,
              },
              {
                name : "SPAR",
                role: 'User',
                title: null,
                org: null,
                appId: 3,
              },
              {
                name : "NGRI",
                role: 'User',
                title: null,
                org: null,
                appId: 4,
              },
              {
                name : "71.09",
                role: 'User',
                title: null,
                org: null,
                appId: 5,
              },
      ],
      expandedHeaders: [
        { text: 'App Id', value: 'appId' },
        { text: 'Role', value: 'role' },
        { text: 'Title', value: 'title' },
        { text: 'Org', value: 'org' },
      ],

      editedItem: {
      displayName: '',
      email: '',
      customClaims: {
        appLevel: [],
        roles: []
      }
    },
      valid: true,
      apps: [
      { name: 'Admin App', value: 0 },
      { name: 'OPDCS', value: 1 },
      { name: 'CATTS', value: 2 },
      { name: 'SPAR', value: 3 },
      { name: 'NGRI', value: 4 },
      { name: '71.09', value: 5 }
    ],
      appName: [
        { id: 0, name: "Admin Portal" },
        { id: 1, name: "OPDCS" },
        { id: 2, name: "CATTS" },
        { id: 3, name: "SPAR" },
        { id: 4, name: "NGRI" },
        { id: 5, name: "71.09" },
      ],
      selectedEmail : null,
      expanded: [],
      vendors: [],
      emaildialog: false,
      dialog: false,
      selected: [],
      loading: true,
      sortby: [],
      sortDesc: [],
      headers: [
        { text: "Uid", align: "start", sortable: true, value: "uid" },
        { text: "Display Name", align: "start", sortable: true, value: "displayName" },
        { text: "Email", align: "start", sortable: true, value: "email" },
        { text: "Creation Time", align: "start", sortable: true, value: "metadata.creationTime" },
        { text: "Last Sign In Time", align: "start", sortable: true, value: "metadata.lastSignInTime" },
        { text: "Have Access to", align: "start", sortable: true, value: "appLevel" },
        { text: "Actions", value: "actions", sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      search: null,
      users: [],
      usersRaw : [],
      editedIndex: -1,
    };
  },
  async mounted() {
    this.usersRaw = await getAllUsers();
    this.users = this.usersRaw.identity;
    this.loading = false;
  },
  methods: {
  
    async resendEmail(){
      this.resendLoading = true;
      await resetPassword(this.selectedEmail);
      this.selectedEmail = null;
      this.emaildialog = false;
      this.resendLoading = false;
    },
    resendEmailDialog(item){
      this.selectedEmail = item.email;
      this.emaildialog = true;
    },
    combineObjects(firstObj, secondObj) {
  // Create a map of the first object for easier lookup
  const firstObjMap = new Map(firstObj.map(item => [item.appId, item]));
  
  // Start with all items from the second object
  const result = secondObj.map(item => {
    const matchingItem = firstObjMap.get(item.appId);
    if (matchingItem) {
      // If there's a matching item in the first object, merge them
      return {
        ...item,           // Start with all properties from the second object
        ...matchingItem,   // Overwrite with properties from the first object
        name: item.name    // Ensure we keep the 'name' property from the second object
      };
    }
    // If no matching item, return the item from the second object as is
    return item;
  });
  
  // Add any items from the first object that weren't in the second object
  firstObj.forEach(item => {
    if (!result.some(r => r.appId === item.appId)) {
      result.push(item);
    }
  });
  
  return result;
},
    formatName(appIds) {
      return appIds.map(a => this.appName.find(app => app.id === a).name);
    },
   
   
  
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      const combinedObjects = this.combineObjects(this.editedItem.customClaims.roles,this.rolesList)
    

      this.editedItem.customClaims.roles = combinedObjects;
      this.dialog = true;
    },

  },
};
</script>
<style scoped>
.v-data-table ::v-deep tbody tr:hover {
  background-color: #f5f5f5;
}

.v-data-table ::v-deep .v-data-table__expanded__content {
  box-shadow: inset 0 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0 -4px 8px -4px rgba(0, 0, 0, 0.1);
}

.v-card {
  transition: all 0.3s ease-in-out;
}



.user-edit-card {
  max-width: 900px;
  margin: 0 auto;
}

.headline {
  font-size: 1.5rem !important;
}

.subtitle-1 {
  font-size: 1.1rem !important;
}
</style>